<template>
  <div>
    <map-component></map-component>
    <workzone-table></workzone-table>
  </div>
</template>
<script>
import WorkzoneTable from '../components/tables/WorkzoneTable.vue';

import MapComponent from '@/components/MapComponent.vue';

export default {
  name: 'WorkzoneView',
  components:{ WorkzoneTable, MapComponent },
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>