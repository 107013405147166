<template>
  <div>
    <v-container>
    <v-row>

      <v-col cols="12" md="9" style="text-align: left;"><h1>Workzones</h1></v-col>
      <v-col md="3">
        <v-dialog width="500">
  <template v-slot:activator="{ props }">
    <v-btn v-bind="props" text="Create Workzone" class="create_button"><v-icon>mdi-plus</v-icon>Create Workzone</v-btn>
  </template>

  <template v-slot:default="{ isActive }">
    <v-card>
      <v-btn variant="text" text="Close" @click="isActive.value = false"><v-icon>mdi-close</v-icon> Close</v-btn>
        <v-spacer></v-spacer>
      <v-card-text>
        <v-text-field v-model="new_id" label="ID" hint="A system assigned ID will be used if blank"></v-text-field>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        
        <v-btn
          text="Create"
          @click="createWorkZone()"
        ></v-btn>
      </v-card-actions>
    </v-card>
  </template>
</v-dialog>
        
      </v-col>
    </v-row>
    
 <v-data-table
    :headers="headers"
    :items="workzones"
    height="400"
    item-value="name"
    :loading="loading"
  >
  <template #[`item.id`]="{ item }">
      <v-btn :href="'/workzone?id='+item.id" flat>
        {{ item.id }}
      </v-btn>
    </template>
</v-data-table>
</v-container>
</div>
</template>
<script>
import {getWorkzones, createWorkzone} from "../api/ApiCalls";

export default {
    data: () => ({
      workzones: [],
      loading: true,
      new_id: null,
      headers: [
          {title: 'ID', key: 'id'},
          {title: 'Name',  key: 'name'},
          {title: 'Start Date',  key: 'start_date'},
          {title: 'End Date',  key: 'end_date'},
        ],
    }),
    async mounted(){
      this.workzones = await getWorkzones()
      this.loading = false

    },
    methods:{
      async createWorkZone(){
        this.new_id = await createWorkzone(this.new_id)
        this.$router.push({ name: 'workzone-editor',query: { id: this.new_id } })
      }

    }
  }
</script>
<style scoped>
.create_button{
  color: #fff;
  background: #214c2c;
}
</style>
