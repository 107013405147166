import { createStore } from 'vuex';

export default createStore({
  state: {
    user:{
      name: null,
      token: null,
      loggedIn: false
    },
    error: null
    
  },
  mutations: {},
  actions: {},
  getters:{
    auth(){
      return sessionStorage.getItem('loggedIn')
    },
    error_get(){
      return sessionStorage.getItem('error')
    },
    error_set(error){
      return sessionStorage.setItem('error',error)
    }
  }
});