<template>
  <v-container>
    <v-overlay v-model="overlay" class="align-center justify-center">
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular></v-overlay>
    <v-row>
      <v-col><strong>ID: </strong>{{ id }}</v-col>
      <v-col><v-btn v-on:click="update()" color="success">Update</v-btn></v-col>
    </v-row>
    <div ref="mapContainer" class="map-container" ></div>

    <v-stepper v-model="e1">
        <v-stepper-header>
          <v-stepper-item title="Core Details" value="1" editable></v-stepper-item>
          <v-stepper-item title="Location Info" value="2" editable></v-stepper-item>
          <v-stepper-item title="Date Info" value="3" editable></v-stepper-item>
          <v-stepper-item title="Impact" value="4" editable></v-stepper-item>
          <v-stepper-item title="Geometry" value="5" editable></v-stepper-item>
          <v-stepper-item title="Notification Details" value="6" editable></v-stepper-item>
        </v-stepper-header>
    
        <v-stepper-window style="min-height: 50vh;">

          <v-stepper-window-item key="core-details" value="1">
   <v-row>
 <v-col>
   <v-text-field  v-model="workzone.name"  label="Name"  required></v-text-field>
 </v-col>
</v-row>

<v-row>
 <v-col>
   <v-textarea  v-model="workzone.description" label="Description"></v-textarea >
 </v-col>
</v-row>
          </v-stepper-window-item>
          <v-stepper-window-item key="location-info" value="2">
 
<v-row>
  <v-col>
    <v-text-field  v-model="workzone.road_names"  label="Road Name"  required></v-text-field>
  </v-col>
  <v-col>
    <v-select :items="directions" label="Direction" v-model="workzone.direction"></v-select>
  </v-col>
  </v-row>
  <v-row>
  <v-col>
    <v-text-field  v-model="workzone.beginning_milepost"  label="Beginning Milepost"  required></v-text-field>
  </v-col>
  <v-col>
    <v-checkbox  label="Start Location Verified" v-model="workzone.is_start_position_verified"></v-checkbox >
  </v-col>
  <v-col>
    <v-text-field  v-model="workzone.ending_milepost"  label="Ending Milepost"  required></v-text-field>
  </v-col>
  <v-col>
    <v-checkbox  label="End Verified" v-model="workzone.is_end_position_verified"></v-checkbox >
  </v-col>
</v-row>
          </v-stepper-window-item>
          <v-stepper-window-item key="date-details" value="3">
            
    <v-row>
      <v-col>
        <label>Start Date</label>
        <VueDatePicker v-model="workzone.start_date" />
      </v-col>
      <v-col>
        <v-checkbox  label="Start Date Verified" v-model="workzone.is_start_date_verified"></v-checkbox >
      </v-col>
      <v-col>
        <label>End Date</label>
        <VueDatePicker v-model="workzone.end_date" />
      </v-col>
      <v-col>
        <v-checkbox  label="End Date Verified" v-model="workzone.is_end_date_verified"></v-checkbox >
      </v-col>
    </v-row>
          </v-stepper-window-item>
          <v-stepper-window-item key="location-info" value="4">
         
    <v-row>
      <v-col>
        <v-select  v-model="workzone.vehicle_impact"  label="Vehicle Impact" :items="vehicle_impacts"  required></v-select>
      </v-col>
      <v-col>
        <v-text-field  v-model="workzone.reduced_speed_limit_kph"  label="Reduced Speed Limit (KPH)"  required></v-text-field>
      </v-col>
    </v-row>
          </v-stepper-window-item>
          <v-stepper-window-item key="geometry-info" value="5">
            <v-textarea v-model="workzone.geometry" label="Geometry">

            </v-textarea>
          
        
       </v-stepper-window-item>
       <v-stepper-window-item key="notification-details" value="6">
        <v-row>
            <v-col>
              <label>Start Date</label>
              <VueDatePicker v-model="notification_info.closure_start_date" />
            </v-col>
            <v-col>
              <label>End Date</label>
              <VueDatePicker v-model="notification_info.closure_end_date" />
              </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field  v-model="notification_info.road_name"  label="Road Name"  required></v-text-field>
            </v-col>
            <v-col>
              <v-text-field  v-model="notification_info.road_section"  label="Road Section"  required></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field  v-model="notification_info.direction"  label="Direction"  required></v-text-field>
            </v-col>
            <v-col>
              <v-text-field  v-model="notification_info.lane"  label="Lane"  required></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field  v-model="notification_info.county"  label="County"  required></v-text-field>
            </v-col>
            <v-col>
              <v-text-field  v-model="notification_info.district"  label="District"  required></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field  v-model="notification_info.bridge_number"  label="Bridge Number"  required></v-text-field>
            </v-col>
            <v-col>
              <v-text-field  v-model="notification_info.road_index"  label="Index"  required></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field  v-model="notification_info.location_name"  label="Location Name"  required></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field  v-model="notification_info.permit_number"  label="Permit Number"  required></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea  v-model="notification_info.comments" label="Comments"></v-textarea >
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea  v-model="notification_info.work_program_description" label="Work Program Description"></v-textarea >
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field  v-model="notification_info.fdot_contact"  label="FDOT Contact"  required></v-text-field>
            </v-col>
            <v-col>
              <v-text-field  v-model="notification_info.county_contact"  label="County Contact"  required></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field  v-model="notification_info.city_contact"  label="City Contact"  required></v-text-field>
            </v-col>
            <v-col>
              <v-text-field  v-model="notification_info.project_contact"  label="Project Contact"  required></v-text-field>
            </v-col>
          </v-row>
        
       </v-stepper-window-item>
        </v-stepper-window>
      
        
         

        

  
    </v-stepper>
</v-container>
</template>
<script>
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { updateWorkzone,getWorkzone } from "@/components/api/ApiCalls";


mapboxgl.accessToken = 'pk.eyJ1IjoiamRlbm4zMSIsImEiOiJjazFyemg5MTMwYW4wM25uczM4dTdmaDUxIn0.1afg4XvfC1bG41-SjxLAMg'

export default {
  name: 'WorkzoneAdd',
  components:{ VueDatePicker  },
  props: {},
  data: () => ({
    e1: 6,
    steps: 6,
    directions: ['northbound','southbound','eastbound','westbound'],
    vehicle_impacts: ['all-lanes-closed','some-lanes-closed','all-lanes-open'],
    workzone: {geometry:[]},
    notification_info: {},
    map: null,
    id: null,
    draw: null,
    overlay: false,
    created: false,
  }),
  methods:{
    formChanged(){
    this.hasChanged = true
  },
    updateFeatures: function(){
      let geometries = []
      let featurecollection = this.draw.getAll()
      for (let i in featurecollection.features) {
        let geometry = featurecollection.features[i].geometry
        geometries.push(geometry)
      }
      this.workzone.geometry = JSON.stringify(geometries)
    },
     update: async function(){
        this.overlay = true
        if(this.workzone.geometry){
          this.notification_info.img = this.map.getCanvas().toDataURL("image/jpeg", 1.0)
          console.log(this.notification_info.img)
        }else{
          this.notification_info.img = null }
        await updateWorkzone({workzone:this.workzone,notification:this.notification_info})
        this.hasChange = false
        this.overlay= false
    
    }
  },
  async mounted(){
    this.overlay = true
    this.id = this.$route.query.id
    if(!this.id){
      this.$router.push({ name: 'workzones' })
    }
    const wz_response = await getWorkzone(this.id)
    console.log(wz_response)
    this.workzone = wz_response.workzone
    this.notification_info = wz_response.notification
    const map = new mapboxgl.Map({
      container: this.$refs.mapContainer,
      style: "mapbox://styles/mapbox/streets-v12", // Replace with your preferred map style
      center: [ -82.349396, 28.189453],
      zoom: 9,
      preserveDrawingBuffer: true
    });
  map.on('load', () => {
      const draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: { line_string : true, polygon: false, trash: true},
        defaultMode: 'draw_line_string'
      });
    
    map.addControl(draw);
    let geos = JSON.parse(this.workzone.geometry)
    for(let geo in geos){
      console.log(geos[geo])
      draw.add(geos[geo])
    }
    this.draw = draw
  });
  this.map = map;
  this.map.on('draw.create',this.updateFeatures)
  this.map.on('draw.delete',this.updateFeatures)
  this.map.on('draw.update',this.updateFeatures)
  this.overlay= false
},

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.map-container {
  height: 30vh;
  flex: 1;
  z-index: 900;
}
.mapboxgl-popup {
max-width: 400px;
font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
}
.v-stepper-window{
 min-height: 80vh;
}
</style>