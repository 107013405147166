<template>
    <v-container fluid fill-height class="login-form">
    <v-row align="center" justify="center" class="ma-10">
      <v-col md="6">
          <login-component></login-component>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LoginComponent from '../components/forms/LoginComponent.vue'
export default {
  name: 'LoginView',
  components:{LoginComponent},
  props: {
  },
  created() {
    if(this.$store.getters.auth){
     this.$router.push({ path: '/workzones' })
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login-form{
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../assets/wzdx_d7_2.png');
}
</style>
