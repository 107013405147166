<template>
    <v-layout>
    <v-app-bar color="#1A2C3F" app>
      <v-img src="https://webincludes.dot.state.fl.us/images/highres/png/FDOT_Logo_white.png" alt="FDOT Logo"></v-img>
        
      <v-spacer></v-spacer>
    <v-btn href="/workzones" v-if="$store.getters.auth">
          <v-icon>mdi-account-hard-hat</v-icon> Work Zones
    </v-btn>
    
    <v-btn href="/" v-if="!$store.getters.auth">
          <v-icon>mdi-home</v-icon> Home
    </v-btn>
    <v-btn href="/logout" v-if="$store.getters.auth">
          <v-icon>mdi-logout</v-icon> Logout
    </v-btn>
    <v-btn  href="/login" v-else>
          <v-icon>mdi-login</v-icon> Login
    </v-btn>
    </v-app-bar>

    <v-main>
      <h3>Work Zone Data Exchange</h3>
      <v-banner v-if="$store.getters.error_get" style="color: #770B0C;" dismissible>
        <strong>ERROR: </strong>
        {{ $store.getters.error_get }}
      </v-banner>
      <router-view/>
    </v-main>
    <v-footer
    class="footer"
      app
    >
    <v-container>
      <v-row>
        <v-col cols="12" md="2">
          <a title="Click to visit MyFlorida.com -- Opens new browser window" target="_blank" href="http://www.MyFlorida.com">
            <img id="imgMyFlorida" title="Click to visit MyFlorida.com -- Opens new browser window" src="https://webincludes.dot.state.fl.us/images/myflorida.png" style="border-width: 0px;">
          </a>
        </v-col>
        <v-col cols="12" md="8">
          Report Technical Problems to the Service Desk <br>
          Call: <a href="tel:+18669554357">1-866-955-4357 (HELP)</a><br> 
          E-mail: <a title="Use this Link to E-Mail Concerns, Problems, and/or Comments" href="mailto:FDOT.ServiceDesk@dot.state.fl.us?subject=Workzone Data Exchange Issue">Service Desk</a>
          <br>
          <a href="http://www.fdot.gov/agencyresources/applications.shtm" target="_blank">Applications Listing</a>&nbsp;|&nbsp;<a href="http://www.fdot.gov/agencyresources/webpoliciesandnotices.shtm" target="_blank">Web Policies and Notices</a>&nbsp;|&nbsp;<a href="http://www.fdot.gov/agencyresources/notices/accessibility.shtm" target="_blank">Accessibility Statement</a>
        </v-col>
        <v-col cols="12" md="2">
        </v-col>
      </v-row>
        
      </v-container>
    </v-footer>
  </v-layout>
</template>

<style>
#app {
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
.footer{
  background: #D2D7E1;
  font-size: x-small;
}
</style>
