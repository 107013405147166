<template>
    
      <v-btn v-on:click="login" size="x-large"><v-icon>mdi-microsoft</v-icon>Login with Microsoft</v-btn>
    
  </template>
  <script>
  import * as Msal from "msal";
  export default {
    data: () => ({
      response: null
      
    }),
    methods:{
      async login(){
        let config = new Msal.UserAgentApplication({ // MSAL Configuration
 auth: {
    clientId: '9544a681-2392-44a6-acc8-ae84e666c203',
    authority: 'https://login.microsoftonline.com/5202ec34-acf1-4abe-b109-e55a8eaa3d9a',
    redirectUri: window.location.origin,
},
})
const scopes = {scopes: ["User.ReadWrite"],prompt: "select_account"}

config.loginPopup(scopes)
        .then(response => {
            // handle response
            console.log(response)
            this.response = response
            sessionStorage.setItem('loggedIn', true)
            sessionStorage.setItem('token', response.idToken.rawIdToken)
            location.reload();
            this.$router.push({ path: '/workzones' })
        })
        .catch(err => {
            // handle error
            console.log(err)
        });



        

        
      }
    }
  }
</script>