import axios  from "axios";
const HTTP = axios.create({
    baseURL: 'https://api.workzonedata.com/api/v1/',
  })
  const access_token = sessionStorage.getItem('token')
  HTTP.defaults.headers.common['Authorization'] = `Bearer ${access_token}` 
  
export async function getWorkzones() {
    const response = await HTTP.get('/workzones')
    return response.data;
    }
    export async function getWorkzoneGeo() {
      const response = await HTTP.get('/workzones/geo')
      return response.data;
      }
  export async function getWorkzone(id) {
    const response = await HTTP.get('/workzone',{params:{id:id}})
    return response.data;
    }
    export async function createWorkzone(id) {
      let params = {}
      if(id){
        params = {id: id}
      }
      const response = await HTTP.post('/workzone',params)
      return response.data
      }

    export async function updateWorkzone(workzone) {
      const response = await HTTP.put('/workzone', workzone)
      return response.data;
      }