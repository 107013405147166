import { createApp } from 'vue'
import 'vuetify/styles'


import { createRouter, createWebHistory } from 'vue-router'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import DatetimePicker from 'vuetify-datetime-picker'

import store from './store'
import App from './App.vue'

import { aliases, mdi } from 'vuetify/iconsets/mdi'
import '@mdi/font/css/materialdesignicons.css'
import WelcomeView from './views/WelcomeView.vue'
import LoginView from './views/LoginView.vue'
import LogoutView from './views/LogoutView.vue'
import WorkzoneView from './views/WorkzoneView.vue'
import WorkzoneUpdate from './views/WorkzoneUpdate.vue'
import PageNotFound from './components/PageNotFound.vue'

const vuetify = createVuetify({
    icons:{
        defaultSet: 'mdi',
        aliases,
        sets:{mdi}
    },
    components,
    directives,
  })

  const router = createRouter({
    history: createWebHistory(),
    routes: [
        { 
            path: '/', 
            component: WelcomeView,
            meta: {
                authRequired: false 
            }
        },
        { 
            path: '/login', 
            component: LoginView,
            meta: {
                authRequired: false 
            }
        },
        { 
            path: '/:pathMatch(.*)*',
            component: PageNotFound,
            meta: {
                authRequired: false 
            }
        },
        { 
            path: '/logout', 
            component: LogoutView,
            meta: {
                authRequired: false 
            }
        },
        { 
            path: '/workzones',
            name:"workzones",
            component: WorkzoneView,
            meta: {
                authRequired: true 
            }
        },
        { 
            path: '/workzone',
            name: 'workzone-editor',
            component: WorkzoneUpdate,
            meta: {
                authRequired: true 
            }
        },
    ]
});
router.beforeEach((to, from, next) => {
    if (to.meta.authRequired) {
        var isAuthenticated= store.getters.auth;
        if(isAuthenticated) {
                return next(); // allow to enter route
           } else{
            store.state.error = "Login required. If you are having issues logging in please contact the helpdesk"
                
                return next('/login'); // go to '/login';
           }
        
       }else{
        return next();
       }
       
  })

const app = createApp(App)
app.use(store)
app.use(DatetimePicker)
app.use(router)
app.use(vuetify)
app.mount('#app')
