<template>
  <v-container>
<p style="padding-top: 50px;">Welcome to the WorkZone Data Exchange platform, your comprehensive solution for seamless data management within our state organization. Designed to streamline collaboration and enhance efficiency, our platform offers robust features tailored to meet the unique needs of government agencies and departments.</p>
  </v-container>
</template>

<script>
export default {
  name: 'WelcomeView',
  props: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
