<template>
  <v-container>
    <v-overlay v-model="overlay" class="align-center justify-center">
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular></v-overlay>
    <div ref="mapContainer" class="map-container"></div>
</v-container>
</template>
<script>
import mapboxgl from "mapbox-gl";
import "../../node_modules/mapbox-gl/dist/mapbox-gl.css"
mapboxgl.accessToken = 'pk.eyJ1IjoiamRlbm4zMSIsImEiOiJjazFyemg5MTMwYW4wM25uczM4dTdmaDUxIn0.1afg4XvfC1bG41-SjxLAMg'
import { getWorkzoneGeo } from "./api/ApiCalls";
export default {
    data: () => ({
      workzones: [],
      overlay: true,
      headers: [
          {title: 'ID', key: 'id'},
          {title: 'Name',  key: 'name'},
          {title: 'Start Date',  key: 'start_date'},
          {title: 'End Date',  key: 'end_date'},
        ],
    }),
   async mounted () {
  const map = new mapboxgl.Map({
    container: this.$refs.mapContainer,
    style: "mapbox://styles/mapbox/streets-v12", // Replace with your preferred map style
    center: [ -82.349396,28.189453],
    zoom: 9,
  });
  map.on('load', async () => {
    const wz_geom =  await getWorkzoneGeo();
    map.addSource('workzones', {type: 'geojson',data: wz_geom});
    map.addLayer({'id': 'workzones-layer',
    'type': 'line',
    'source': 'workzones',
    'paint': 
    {'line-width': 6,'line-color': 'blue',}});
    //click
    map.on('click', 'workzones-layer', (e) => {
      console.log(e.features[0].properties)
      let properties = e.features[0].properties;
      const HTML = "<h4>"+properties.name+"</h4><p>"+properties.description+"</p><p><a href='../workzone?id="+properties.id+"'>View</a>"
      const coordinates = e.lngLat;
      
      new mapboxgl.Popup().setLngLat(coordinates).setHTML(HTML).addTo(map);
 });
 
    map.on('mouseenter', 'workzones-layer', () => {
      map.getCanvas().style.cursor = 'pointer';
    });
 
    map.on('mouseleave', 'workzones-layer', () => {
      map.getCanvas().style.cursor = '';
    });

    //endclick


  });
  
  this.map = map;
  this.overlay = false
},
unmounted() {
  this.map.remove();
  this.map = null;
}
  }
</script>
<style scoped>
.map-container {
  height: 20vh;
  flex: 1;
}
.mapboxgl-popup {
max-width: 400px;
font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
}
</style>
