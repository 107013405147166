<template>
  <v-overlay>
<v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular></v-overlay>
</template>

<script>
export default {
  data: () => ({
  }),
   mounted(){
    this.$store.state.user.loggedIn = false
    sessionStorage.clear();
    window.location.replace('/')
  }
}
</script>

<style scoped></style>
